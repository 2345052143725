import styled from 'styled-components';
import { EntityTypes } from '../../../new-lib/constants/common';
import { commifyNumber } from '../../../helpers/common';
import { useTranslation } from 'next-i18next';

interface PriceDetailsProps {
  entityType: EntityTypes;
  entityData: {
    min_down_payment: number;
    max_installment_years: number;
    max_installment_years_months?: number;
    min_installments: number;
    min_price: number;
    currency: string;
    isNawyNow?: boolean;
  };
}

const PriceDetails = ({ entityData, entityType }: PriceDetailsProps) => {
  const maxInstallments =
    entityData?.max_installment_years_months &&
    entityData?.max_installment_years_months > 0
      ? `${entityData?.max_installment_years}.${entityData?.max_installment_years_months}`
      : `${entityData?.max_installment_years}`;

  const getCompoundData = () => {
    return entityData.min_down_payment && entityData.min_down_payment > 0 ? (
      <div className="installment">
        <span>
          {commifyNumber(entityData.min_down_payment) + ' ' + t('downpayment')}/
        </span>

        {entityData.max_installment_years &&
        entityData.max_installment_years > 0 ? (
          <span>{maxInstallments + ' ' + t('years')}</span>
        ) : (
          ''
        )}
      </div>
    ) : (
      ''
    );
  };
  const getPropertyData = () => {
    return entityData.min_installments &&
      entityData.max_installment_years &&
      entityData.min_installments > 0 &&
      entityData.max_installment_years > 0 ? (
      <div className="installment" data-cy="card-payment">
        <span data-cy="card-installments">
          {commifyNumber(entityData.min_installments) + ' '}
          {t('monthly')}
        </span>

        <span>{t(' / ') + maxInstallments + ' ' + t('years')}</span>
      </div>
    ) : (
      ''
    );
  };
  const { t } = useTranslation('common');
  const getPaymentDetails = () => {
    return entityType == EntityTypes.COMPOUND
      ? getCompoundData()
      : getPropertyData();
  };
  return (
    <Container isNawyNow={entityData.isNawyNow}>
      <div className="payment-details">{getPaymentDetails()}</div>

      {entityData.min_price && entityData.min_price > 0 ? (
        <div className="price">
          <span data-cy="card-price">
            <div>{commifyNumber(entityData.min_price)}</div>
          </span>
          <span>{entityData.currency || 'EGP'}</span>
        </div>
      ) : (
        <div className="price">
          <span>{t('price_tba')}</span>
        </div>
      )}
    </Container>
  );
};

const Container = styled.div<any>`
  display: flex;
  flex-direction: column;
  .payment-data {
    display: flex;
    flex-direction: row;
    span {
      color: ${props => props.theme.light.colors.midGray};
    }
  }
  .price {
    display: flex;
    align-items: center;
    gap: 0px 5px;
    font-family: materialBold;
    font-size: ${props => props.theme.font.sizes.xl};
    color: ${props => props.theme.light.colors.black};
    color: ${props =>
      props.isNawyNow
        ? `${props.theme.light.colors.white}`
        : `${props.theme.light.colors.black}`};
  }
  .installment {
    font-family: ${props =>
      props.isNawyNow ? 'materialLight' : 'materialRegular'};
    color: ${props =>
      props.isNawyNow ? `${props.theme.light.colors.white}` : `#646464`};
    font-size: ${props => props.theme.font.sizes.xxs};
    line-height: 1.5;
    text-align: start;
  }
`;
export default PriceDetails;
