import styled from 'styled-components';
import { tagsBackgroundColor, tagsColor } from '../../helpers/cards';
import { useTranslation } from 'next-i18next';

interface CardLabelProps {
  entityTypeLabel?: string;
  entityBadgeLabel?: string;
}

const CardBadge = ({ entityTypeLabel, entityBadgeLabel }: CardLabelProps) => {
  const { t } = useTranslation('common');

  const getCardTag = () => {
    if (entityBadgeLabel === 'Resale' || entityBadgeLabel === 'إعادة بيع') {
      return t('resale');
    }
    return entityBadgeLabel;
  };
  return (
    <Container>
      <div className="card-label">
        {entityTypeLabel && <span>{entityTypeLabel}</span>}
        {entityBadgeLabel && (
          <div
            className="card-promotion"
            style={{
              background: tagsBackgroundColor.get(entityBadgeLabel),
              color: tagsColor.get(entityBadgeLabel)
            }}
          >
            {getCardTag()}
          </div>
        )}
      </div>
    </Container>
  );
};

const Container = styled.div`
  .card-label {
    display: flex;
    min-height: 31px;
    align-items: center;
    gap: 8px;
    background-color: #ffffff;
    border-radius: 4px;
    font-family: materialMedium;
    color: ${props => props.theme.light.colors.black};
    padding: 4px 8px;
    font-size: 15px;
    .card-promotion {
      font-family: materialSemiBold;
      padding: 10px;
      font-size: ${props => props.theme.font.sizes.xxs};
      color: white;
      border-radius: 4px;
    }
  }
`;

export default CardBadge;
